import React from 'react';
import { List, Typography } from "antd";
import UIfx from 'uifx';

import conversationsListStyles from "./assets/ConversationsList.module.css";
import conversationsItemStyles from "./assets/ConversationsItem.module.css";
import Conversation  from './Conversation';
import beepAudio from './assets/beep.mp3';

import { joinClassNames } from "./utils/class-name";

const { Text } = Typography;

const beep = new UIfx(beepAudio);

export class ConversationsList extends React.Component {
    constructor(props){
        console.info('loading conversation list');
        super(props);
        this.state = { conversations: [], selectedConversation: null, unread: [] };
    }

    addConversation(conversation){
        let current = this.state.conversations.filter((n) => n === conversation);
        if (!current.length){
            this.setState({conversations: [...this.state.conversations, conversation]});
            conversation.on('messageAdded', message => {
                if (this.state.selectedConversation !== message.conversation.sid){
                    let unread = this.state.unread;
                    if (unread[message.conversation.sid]){
                        unread[message.conversation.sid]++;
                    } else {
                        unread[message.conversation.sid] = 1;
                    }
                    this.setState({unread: unread});
                }
                beep.play();
            });
        }
    }

    selectedConversation(conversation){
        let current = this.state.conversations.filter((n) => n === conversation);
        this.setState({unread: this.state.unread.filter((a,idx) => idx !== conversation.sid), selectedConversation: conversation.sid});
    }

    render() {
        const { conversations, selectedConversationSid, onConversationClick } = this.props;
        return (
            <List
                header={"Open Conversations"}
                className={conversationsListStyles['chat-channels-list']}
                bordered={true}
                loading={this.state.conversations.length === 0}
                dataSource={this.state.conversations}
                renderItem={item => {
                    const activeChannel = item.sid === selectedConversationSid;
                    const unread = this.state.unread[item.sid] ? ' (' + this.state.unread[item.sid] + ')' : null;
                    const conversationItemClassName = joinClassNames([
                        conversationsItemStyles['conversation-item'],
                        activeChannel && conversationsItemStyles['conversation-item--active'], conversationsItemStyles[unread ? 'has-unread' : 'no-unread'], 
                        conversationsItemStyles[activeChannel ? 'selected' : 'not-selected']
                    ]);
                    
                    var itemTextClassName = null;
                    if (unread) {
                        itemTextClassName = conversationsItemStyles['has-unread'];
                     } else if (activeChannel){
                         itemTextClassName = conversationsItemStyles['selected'];
                     }

                    return (
                        <List.Item
                            key={item.sid}
                            onClick={() => onConversationClick(item)}
                            className={conversationItemClassName}
                        >
                            <Text
                                strong
                                className={itemTextClassName}
                            >
                                {item.friendlyName || item.sid}{unread}
                            </Text>
                        </List.Item>
                    )
                }}
            />
        )
    }
}
